<template>
  <Navigation />
  <Header />
  <router-view/>
  <Suspense>
    <template #default>
      <Footer />
    </template>
    <template #fallback>
      Loading
    </template>
  </Suspense>
</template>
<script>
import Navigation from '@/components/partials/Navigation.vue'
import Header from '@/components/partials/Header.vue'
import Footer from '@/components/partials/Footer.vue'

export default {
  components: {
    Header,
    Navigation,
    Footer
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  padding-top: 10rem;
  // background-color: rgba(0,0,0,.3);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
