export function useExtractCategories(items){
    const categories = ['relevance', 'priority']
    let catalogs = {}
    for (let cat of categories) {
        let catSet = new Set
        for (let item of items) {
            if(item.meta[cat]) {
                // console.log('item.meta[cat]', item.meta[cat])
                catSet.add(item.meta[cat])
            }
        }
        catalogs[cat] = Array.from(catSet)
    }
    return catalogs
    // return items
}