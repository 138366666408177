<template>
    <div class="footer">
        <div class="column">
            <div class="footer-content" 
                v-html="footerContent.column_1"
            />
        </div>
        <div class="column">
            <div class="footer-content" 
                v-html="footerContent.column_2"
            />
        </div>
    </div>
    
</template>
<script>
import { useDnsApiData } from '@/use/dns-api-data'

export default {
    name: 'Footer',

    async setup() {
        const { footerContent } = await useDnsApiData()
        return {
            footerContent
        }
    }
}
</script>
<style lang="scss">
.footer {
    background-color: #333;
    color: #eee;
    min-height: 12rem;
    padding: 1.5rem 2rem 2rem 2rem;
    line-height: 1.4rem;
    display: grid;
    grid-template-columns: 2fr 3fr 1fr 1fr;
    gap: 2.5rem;
    font-size: .8rem;
    h3 {
        margin: 0 0 1rem 0;
    }
    a {
        color: #eee;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}


</style>