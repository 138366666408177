import { createStore } from 'vuex'

export default createStore({
  state: {
    headerTitle: 'Übersicht',
    categorized: false,
    headerCategorizationToggle: false
  },
  getters: {
    getHeaderTitle(state) {
      return state.headerTitle
    }
  },
  mutations: {
    setHeaderTitle(state, payload) {
      state.headerTitle = payload
    },
    toggleCategorized(state) {
      state.categorized = !state.categorized
    },
    setHeaderCategorizationToggle(state, payload) {
      state.headerCategorizationToggle = payload
    }
  },
  actions: {
    setHeaderTitle({ commit }, payload) {
      commit('setHeaderTitle', payload)
    },
    toggleCategorized({ commit }) {
      commit('toggleCategorized')
    },
    setHeaderCategorizationToggle({ commit }, payload) {
      commit('setHeaderCategorizationToggle', payload)
    }
  },
  modules: {
    
  }
})
