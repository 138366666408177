import axios from 'axios'
const mappingCaseStudies = {
    title: {
        label: 'Bezeichnung',
        type: 'output'
    },
    description: {
        label: 'Beschreibung',
        type: 'output'
    },
    operational_status: {
        label: 'Status',
        order: ['ausgewertet', 'abgeschlossen', 'in Arbeit', 'in Planung', 'Vorgespräche'],
        type: 'output'
    },
    primary_partners: {
        label: 'Primäre Projektpartner',
        type: 'output'
    },
    secondary_partners: {
        label: 'Primäre Projektpartner',
        type: 'output'
    },
    sections: {
        type: 'meta'
    }

}
const mappingFieldsOfAction = {
    title: {
        label: 'Bezeichnung',
        type: 'output'
    },
    color: {
        type: 'meta'
    },
    position: {
        type: 'meta'
    }

}
const mappingSections = {
    title: {
        label: 'Bezeichnung',
        type: 'output'
    },
    description: {
        label: 'Definition',
        type: 'output'
    },
    relevance: {
        type: 'meta'
    },
    priority: {
        type: 'meta'
    },
    field_of_action: {
        type: 'meta'
    },
    type_of_action: {
        type: 'meta'
    }
}

export async function usePageBySlugData(slug) {
    const pagesApi = 'https://cms.bua-dns.de/json/pages/'
    const response = await axios.get(pagesApi + slug)
    const pageData = {
        id: response.data.data.id,
        title: response.data.data.attributes.title,
        body: response.data.data.attributes.body,
    }
    return pageData
}

export async function useDnsApiData() {
    const pagesResponse = await axios.get('https://cms.bua-dns.de/json/pages/?page[size]=1000')
    const fieldsOfActionResponse = await axios.get('https://cms.bua-dns.de/json/fields_of_action?page[size]=1000')
    const typesOfActionResponse = await axios.get('https://cms.bua-dns.de/json/types_of_action?page[size]=1000')
    const sectionsResponse = await axios.get('https://cms.bua-dns.de/json/sections?page[size]=1000')
    const caseStudiesResponse = await axios.get('https://cms.bua-dns.de/json/case_studies?page[size]=1000')
    const footerContentResponse = await axios.get('https://cms.bua-dns.de/json/footer')
    const footerContent = footerContentResponse.data.data[0].attributes
    const pages = pagesResponse.data.data
    const caseStudies = caseStudiesResponse.data.data
        .map(caseStudy => {
            let mapped = {}
            mapped.id = caseStudy.id
            mapped.output = {}
            mapped.meta = {}
            for (let f in mappingCaseStudies) {
                if(caseStudy.attributes[f]) {
                    if(mappingCaseStudies[f]['type'] === 'output') {
                        mapped.output[f] = caseStudy.attributes[f]
                    }
                    if (mappingCaseStudies[f]['type'] === 'meta') {
                        mapped.meta[f] = caseStudy.attributes[f]
                    }
                }
            }
            return mapped
        })
        .sort((a,b) => {
            const indexA = mappingCaseStudies.operational_status.order.indexOf(a.output.operational_status)
            const indexB = mappingCaseStudies.operational_status.order.indexOf(b.output.operational_status)
            if(indexA < indexB) return -1
            if(indexB < indexA) return 1
            else return 0
        })
    const types_of_action = typesOfActionResponse.data.data.map(item => {
        let mapped = {}
        mapped.id = item.id
        mapped.output = {}
        mapped.meta = {}
        for (let f in mappingFieldsOfAction) {
            if (item.attributes[f]) {
                if (mappingFieldsOfAction[f]['type'] === 'output') {
                    mapped.output[f] = item.attributes[f]
                }
                if (mappingFieldsOfAction[f]['type'] === 'meta') {
                    mapped.meta[f] = item.attributes[f]
                }
            }
        }
        return mapped
    })
    const fields_of_action = fieldsOfActionResponse.data.data.map(item => {
        let mapped = {}
        mapped.id = item.id
        mapped.output = {}
        mapped.meta = {}
        for (let f in mappingFieldsOfAction) {
            if (item.attributes[f]) {
                if (mappingFieldsOfAction[f]['type'] === 'output') {
                    mapped.output[f] = item.attributes[f]
                }
                if (mappingFieldsOfAction[f]['type'] === 'meta') {
                    mapped.meta[f] = item.attributes[f]
                }
            }
        }
        return mapped
    })
    const sections = sectionsResponse.data.data.map(item => {
        let mapped = {}
        mapped.id = item.id
        mapped.output = {}
        mapped.meta = {}
        for (let f in mappingSections) {
            if (item.attributes[f]) {
                if (mappingSections[f]['type'] === 'output') {
                    mapped.output[f] = item.attributes[f]
                }
                if (mappingSections[f]['type'] === 'meta') {
                    mapped.meta[f] = item.attributes[f]
                }
            }
        }
        // Diagramme
        if (item.attributes.diagrams) {
            mapped.diagrams = item.attributes.diagrams.map(d => {
                let diagram = {}
                diagram.caption = d.caption
                    ? d.caption
                    : undefined
                diagram.max_size = d.max_size
                    ? d.max_size
                    : undefined
                diagram.position = d.position
                    ? d.position
                    : undefined
                diagram.url = d.diagram && d.diagram.url 
                    ? d.diagram.url
                    : undefined

                return diagram
            })
        }
        return mapped
    })
    const items = {
        fields_of_action,
        types_of_action,
        sections,
        caseStudies,
        footerContent,
        pages
    } 

    return items
}