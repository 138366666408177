<template>
    <div class="nav">
        <div class="main-nav">
            <router-link to="/">Handlungsfelder</router-link>
            <router-link to="/case-studies">Case Studies</router-link>
            <router-link to="/types">Aktionstypen</router-link>
            <router-link to="/evaluation">Kriterien Evaluation</router-link>         
            <router-link to="/infrastruktur">IT-Infrastruktur</router-link>         
        </div>
    </div>
</template>


<style lang="scss" scoped>
.nav {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    top: 0;
    width: 100%;
    height: 1.6rem;
    font-size: .9rem;
    background-color: #333;
    z-index: 5000;
    .main-nav {
        display: block;
        margin-left: auto;
        padding: .2rem 1rem;
    }
    a {
        display: inline-block;
        margin-left: .6rem;
        text-decoration: none;
        color: #eee;
        font-size: .85rem;
        &:hover {
            color: #ccd6e0;
        }
    }
}
</style>