<template>
    <div class="identity">
        <div class="dns-box">
            <div class="logo-partial letters">
                <span class="bua-red">D</span>
                <span class="bua-green">N</span>
                <span class="bua-blue">S</span>
            </div>
            <div class="logo-partial visual">
                <img src="../../assets/bua_logo_network_symbol.svg" >
            </div>
        </div>
        <div>
            <h1>{{ headerTitle }}</h1>
            <!-- <div class="controls">
                <div
                    class="toggler"
                    v-if="headerCategorizationToggle"
                    @click="toggleCategorized()"
                >
                    wechseln zu:
                    <span v-if="categorized">nicht priorisiert</span>
                    <span v-else>priorisiert</span>
                </div>
            </div> -->
        </div>
        
        <img class="logo" src="../../assets/logo_bua_wide.svg" alt="">

    </div>
    
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'Header',

    setup() {
        const store = useStore()
        const headerCategorizationToggle = computed(() => {
            return store.state.headerCategorizationToggle
        })
        const categorized = computed(() => {
            return store.state.categorized
        })
        function toggleCategorized() {
            store.dispatch('toggleCategorized')
        }
        const headerTitle = computed(() => store.getters.getHeaderTitle)
        return {
            headerTitle,
            categorized,
            toggleCategorized,
            headerCategorizationToggle
        }
    }
}
</script>
<style lang="scss" scoped>
.bua-red {
    color: #c7202d;
}
.bua-green {
    color: #a9d04a;
}
.bua-blue {
    color: #153f6c;
}
.bua-grey {
    color: #979a9b;
}

.identity {
    position: fixed;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    top: 0;
    width: 100%;
    min-height: 8rem;
    box-shadow: 0px 8px 12px -6px rgba(0,0,0,0.74);
    .dns-box {
        font-size: 2.4rem;
        color: rgba(0,0,0, .75);
        font-weight: bold;
        max-width: 16rem;
        padding: 0 1.2rem;
        .logo-partial {
            display: inline-flex;
            align-items: center;
            padding: .6rem 0;
            img {
                display: inline-block;
                position: relative;
                top: .2rem;
                height: 2.4rem;
                padding: .2rem 0 0 .6rem;
            }
        }
    }
    h1 {
        font-weight: normal;
        font-size: 1.4rem;
        text-transform: uppercase;
        margin-top: 1.5rem;
    }
    img.logo {
        display: block;
        max-height: 2rem;
        
    }
    .controls {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        font-size: .85rem;
        .toggler {
            span {
                padding: .2rem .4rem;
                text-align: center;
                border: 1px solid #ddd;
                cursor: pointer;
                &:hover {
                    background-color: #eee;
                } 
            }

        }
    }
}

</style>