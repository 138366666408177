<template>
  <div class="exoponate">
    <Suspense>
      <template #default>
        <Overview />
      </template>
      <template #fallback>
        ... Loading
      </template>
    </Suspense>
  </div>
</template>

<script>
// @ is an alias to /src
import Overview from '@/components/Overview.vue'

export default {
  name: 'Home',
  components: {
    Overview,
  }
}
</script>
